/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import * as Sentry from "@sentry/remix";
import { LocaleContextProvider } from "~/lib/LocaleProvider";

const locales = window.navigator.languages;

// @ts-expect-error We set ENV in root.tsx
if (window.ENV.SENTRY_DSN && window.ENV.SENTRY_DSN !== "") {
  Sentry.init({
    // @ts-expect-error We set ENV in root.tsx
    dsn: window.ENV.SENTRY_DSN,
    // @ts-expect-error We set ENV in root.tsx
    environment: window.ENV.ENVIRONMENT,
    // @ts-expect-error We set ENV in root.tsx
    tracesSampleRate: Number(window.ENV.SENTRY_SAMPLE_RATE),
    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
    ],
    tracePropagationTargets: [/api/],
  });
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <LocaleContextProvider locales={locales}>
        <RemixBrowser />
      </LocaleContextProvider>
    </StrictMode>,
  );
});
